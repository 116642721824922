<template>
  <div id="app">
    <router-view />
    <div class="foot_wrap">
      <img src="@/assets/imgs/email.webp" class="email" />
      <div class="left">
        如果有任何意见或者建议，欢迎邮件到<br />
        <a href="mailto:PR@newmed.cn" class="nota" style="color: white"
          >PR@newmed.cn</a
        >
      </div>
      <div class="left2">
        <a href="http://beian.miit.gov.cn/">沪ICP备16009199号-3</a
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402006614"
          >沪公网安备31011402006614号</a
        >
      </div>
      <div class="right">
        扫描二维码<br />
        关注我们的公众号
      </div>
      <img src="@/assets/imgs/logo2.png" class="logo" />
      <img src="@/assets/imgs/wx.webp" class="wx" />
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => {
      this.linkMobile();
    }, 1000);
  },
  methods: {
    linkMobile() {
      var path = this.$route.path
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      var url = '/mobile';
      let goUrl = flag ? 1 : 0;
      if (goUrl === 1) {
        if(path=='/trainLive'||path=='/live'){
          url = url+'/live.html?id='+this.$route.query.id
        }
        location = url;
      }
      return;
    },
  },
};
</script>

<style lang="less">
@import "./assets/css/reset.css";
body {
  min-width: 1250px;
}

html,
body,
#app {
  height: 100%;
}

img {
  display: block;
}

.min_width {
  min-width: 1250px;
}

.text_2_hidden {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.content_center {
  width: 1250px;
  margin: 0 auto;
}
.foot_wrap {
  background-color: #005ca2;
  width: 100%;
  height: 194px;
  position: relative;
  // margin-top: 300px;
}
.foot_wrap .left2 {
  position: absolute;
  left: 195px;
  bottom: 20px;
  font-size: 16px;
  color: #ffffff;
}
.foot_wrap .left2 a {
  color: #ffffff;
  text-decoration: none;
}
.foot_wrap .email {
  width: 164px;
  position: absolute;
  left: 20px;
  top: 35px;
}
.foot_wrap .wx {
  width: 146px;
  position: absolute;
  right: 37px;
  top: 24px;
}
.foot_wrap .left {
  position: absolute;
  left: 200px;
  top: 55px;
  font-size: 16px;
  color: #ffffff;
}
.foot_wrap .right {
  position: absolute;
  right: 200px;
  bottom: 24px;
  font-size: 14px;
  color: #ffffff;
}
.foot_wrap .logo {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
}
</style>
