<template>
  <transition name="el-fade-in">
    <div class="login" v-if="layerShow">
    
      <!-- <div class="isLogin be_center" v-show="isLoginShow">
        <div @click="onUserLoginShow('ordinary')">普通登录</div>
        <div @click="onUserLoginShow('staff')">员工登录</div>
        <img
          class="close"
          @click="closeLayer('isLoginShow')"
          src="@/assets/imgs/layer/close.png"
          alt=""
        />
      </div>

      <div class="user_login be_center" v-show="userLoginShow">
        <div class="title" v-if="userType == 'ordinary'">用户登录</div>
        <div class="title" v-else-if="userType == 'staff'">员工登录</div>
        <el-form
          :model="loginForm"
          :rules="loginFormRules"
          ref="loginForm"
          class="input_box"
        >
        
          <el-form-item prop="name">
            <el-input
              v-model="loginForm.name"
              :placeholder="`请输入${userType == 'ordinary' ? '用户名' : '公司邮箱'}`"
              @keyup.enter.native="loginSubmit"
            ></el-input>
          </el-form-item>

          <el-form-item prop="passwd">
            <el-input
              type="password"
              v-model="loginForm.passwd"
              placeholder="请输入密码"
              @keyup.enter.native="loginSubmit"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="submit" @click="loginSubmit">确定</div>
        <div class="tips">
          <div class="forget" @click="onForgetPswShow">忘记密码</div>
          <div v-if="userType == 'ordinary'">还未注册，请点此先<i @click="onBindUserShow">注册</i></div>
          <div v-else-if="userType == 'staff'">还未绑定公司邮箱，请点此先<i @click="onBindUserShow">绑定</i></div>
        </div>
        <img
          class="close"
          @click="closeLayer('userLoginShow')"
          src="@/assets/imgs/layer/close.png"
          alt=""
        />
      </div>

      <div class="bind_user user_login be_center" v-show="bindUserShow">
        <div class="title" v-if="userType == 'ordinary'">用户注册</div>
        <div class="title" v-else-if="userType == 'staff'">绑定公司邮箱</div>
        <el-form
          :model="bindUserForm"
          :rules="bindUserFormRules"
          ref="bindUserForm"
          class="input_box"
        >
          <el-form-item prop="name">
            <el-input
              v-model="bindUserForm.name"
              :placeholder="`请输入${userType == 'ordinary' ? '用户名' : '公司邮箱'}`"
              @keyup.enter.native="bindUserSubmit"
            ></el-input>
          </el-form-item>

          <el-form-item prop="pwd">
            <el-input
              type="password"
              v-model="bindUserForm.pwd"
              placeholder="设置密码"
              @keyup.enter.native="bindUserSubmit"
            ></el-input>
          </el-form-item>

          <el-form-item prop="phone">
            <el-input
              v-model="bindUserForm.phone"
              placeholder="手机号"
              maxlength="11"
              @keyup.enter.native="bindUserSubmit"
            ></el-input>
            <div class="getCode" @click="getCode('bindUser')">
              {{
                bindUserCodeT.isGet ? bindUserCodeT.time + "s" : "获取验证码"
              }}
            </div>
          </el-form-item>

          <el-form-item prop="sms">
            <el-input
              v-model="bindUserForm.sms"
              placeholder="输入验证码"
              @keyup.enter.native="bindUserSubmit"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="submit" @click="bindUserSubmit">确定</div>
        <img
          class="close"
          @click="closeLayer('bindUserShow')"
          src="@/assets/imgs/layer/close.png"
          alt=""
        />
      </div>

      <div class="forget_psw bind_user user_login be_center" v-show="forgetPswShow">
        <div class="title">忘记密码</div>
        <el-form
          :model="fotgetPswFrom"
          :rules="fotgetPswFromRules"
          ref="fotgetPswFrom"
          class="input_box"
        >
          <el-form-item prop="name">
            <el-input
              v-model="fotgetPswFrom.name"
              :placeholder="`请输入${userType == 'ordinary' ? '用户名' : '公司邮箱'}`"
              @keyup.enter.native="fotgetPswSubmit"
            ></el-input>
          </el-form-item>

          <el-form-item prop="phone">
            <el-input
              v-model="fotgetPswFrom.phone"
              placeholder="手机号"
              @keyup.enter.native="fotgetPswSubmit"
              maxlength="11"
            ></el-input>
            <div class="getCode" @click="getCode('fotgetPsw')">
              {{
                forgetPswCodeT.isGet ? forgetPswCodeT.time + "s" : "获取验证码"
              }}
            </div>
          </el-form-item>

          <el-form-item prop="sms">
            <el-input
              v-model="fotgetPswFrom.sms"
              placeholder="输入验证码"
              @keyup.enter.native="fotgetPswSubmit"
            ></el-input>
          </el-form-item>

          <el-form-item prop="pwd">
            <el-input
              type="password"
              v-model="fotgetPswFrom.pwd"
              placeholder="设置新密码"
              @keyup.enter.native="fotgetPswSubmit"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="submit" @click="fotgetPswSubmit">确定</div>
        <img
          class="close"
          @click="closeLayer('forgetPswShow')"
          src="@/assets/imgs/layer/close.png"
          alt=""
        />
      </div>

      <div class="fotget_password"></div> -->
    </div>
  </transition>
</template>

<script>
import api from "@/api/serves/login.js";
import { setToken } from "@/utils/auth.js";
export default {
  name: "login",
  props: ["layerShow"], 
  data() {
    return {
      userType: "", // 登录类型
      isYouke: "",
      isLoginShow: true,
      userLoginShow: false,
      bindUserShow: false,
      forgetPswShow: false,
      showNum: 1,
      loginForm: {
        name: "",
        passwd: "",
      },
      loginFormRules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        passwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      bindUserForm: {
        name: "",
        pwd: "",
        phone: "",
        sms: "",
      },
      bindUserFormRules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        pwd: [{ required: true, message: "请设置密码", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        sms: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      bindUserCodeT: {
        t: null,
        time: 60,
        isGet: false,
      },
      fotgetPswFrom: {
        name: "",
        pwd: "",
        phone: "",
        sms: "",
      },
      fotgetPswFromRules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        pwd: [{ required: true, message: "请设置新密码", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        sms: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      forgetPswCodeT: {
        t: null,
        time: 60,
        isGet: false,
      },
    };
  },
  watch: {
    layerShow(val) {
      if(this.layerShow) {
        window.location.href = '/login'
      }
    },
  },
  
  methods: {
    closeLayer(type) {
      this.showNum--;
      // if(to.path != '/' && this.showNum == 0) { // 不是首页并且关闭最后一个弹窗

      // }
      
      switch (type) {
        case "isLoginShow":
          this.isLoginShow = false;
          break;
        case "userLoginShow":
          this.userLoginShow = false;
          break;
        case "bindUserShow":
          this.bindUserShow = false;
          break;
        case "forgetPswShow":
          this.forgetPswShow = false;
          break;
      }

      // if(type == 'youke') {
      //   this.$store.state.user = 'youke'
      // }

      if(this.showNum == 2) this.userLoginShow = true

      if (this.showNum == 0) {
        this.$emit("closeLayer"); //关闭弹窗
        Object.assign(this.$data, this.$options.data());
      }
    },
    onUserLoginShow(type) {
      // ordinary 普通用户   staff 员工
      this.userType = type;

      this.loginFormRules.name[0].message = `请输入${type == 'ordinary' ? '用户名' : '公司邮箱'}`
      this.fotgetPswFromRules.name[0].message = `请输入${type == 'ordinary' ? '用户名' : '公司邮箱'}`


      this.userLoginShow = true;
      this.showNum++;
    },
    returnFalse() {
      return false;
    },
    loginSubmit() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          api.login(this.loginForm).then((res) => {
            setToken(res);
            this.$message.success("登录成功");
            location.reload();
          });
        } else {
          return false;
        }
      });
    },
    bindUserSubmit() { // 注册或绑定
      this.$refs["bindUserForm"].validate((valid) => {
        if (valid) {
          if(this.userType == 'ordinary') {
            let bindUserForm = JSON.parse(JSON.stringify(this.bindUserForm))
            bindUserForm.nickname = bindUserForm.name;
            delete bindUserForm.name
            api.registerr(bindUserForm).then((res) => {
              this.$message.success("注册成功");
              this.closeLayer('bindUserShow')
            });
          } else if(this.userType == 'staff') {
            api.register(this.bindUserForm).then((res) => {
              this.$message.success("绑定成功");
              this.closeLayer('bindUserShow')
            });
          }
          
        } else {
          return false;
        }
      });
    },
    fotgetPswSubmit() {
      this.$refs["fotgetPswFrom"].validate((valid) => {
        if (valid) {
          api.resetPasswd(this.fotgetPswFrom).then((res) => {
            this.$message.success("修改成功");
          });
        } else {
          return false;
        }
      });
    },
    onBindUserShow() {
      this.bindUserShow = true;
      this.showNum++;
      this.userLoginShow = false;
    },
    onForgetPswShow() {
      this.forgetPswShow = true;
      this.showNum++;
      this.userLoginShow = false;
    },
    getCode(e) {
      if (e == "bindUser") {
        if (!this.bindUserCodeT.isGet) {
          if (!this.bindUserForm.phone) {
            this.$message.error("请输入手机号");
            return;
          }
          api.getSms({ phone: this.bindUserForm.phone }).then((res) => {
            this.bindUserCodeT.isGet = true;
            this.bindUserCodeT.t = setInterval(() => {
              this.bindUserCodeT.time--;
              if (this.bindUserCodeT.time == 0) {
                this.bindUserCodeT.t = null;
                this.bindUserCodeT.isGet = false;
                this.bindUserCodeT.time = 60;
              }
            }, 1000);
          });
        }
      } else {
        if (!this.forgetPswCodeT.isGet) {
          if (!this.fotgetPswFrom.phone) {
            this.$message.error("请输入手机号");
            return;
          }
          api.getSms({ phone: this.fotgetPswFrom.phone }).then((res) => {
            this.forgetPswCodeT.isGet = true;
            this.forgetPswCodeT.t = setInterval(() => {
              this.forgetPswCodeT.time--;
              if (this.forgetPswCodeT.time == 0) {
                this.forgetPswCodeT.t = null;
                this.forgetPswCodeT.isGet = false;
                this.forgetPswCodeT.time = 60;
              }
            }, 1000);
          });
        }
      }
    },
  },
  mounted() {

  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005ca2;
@hui: #f4f4f4;
.login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.7);
  z-index: 999;

  .isLogin {
    width: 400px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      cursor: pointer;
      width: 315px;
      line-height: 65px;
      text-align: center;
      color: #fff;
      font-size: 24px;
      background: @skyBlue;
      border-radius: 30px;
      &:first-child {
        margin-bottom: 35px;
      }
    }
  }

  .user_login {
    width: 565px;
    height: 565px;

    .title {
      line-height: 135px;
      background: @sBlue;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-size: 36px;
      color: #fff;
      text-align: center;
    }

    .input_box {
      width: 480px;
      margin: 0 auto;
      margin-top: 60px;

      /deep/.el-input, /deep/.el-input__inner {
        font-size: 24px;
        width: 100%;
        height: 50px;
        box-sizing: border-box;
        // padding: 0 25px;
        border-radius: 30px;
        background: @hui;
        // border: none;
        border: 1px solid @hui;
      }

      .el-form-item {
        margin-bottom: 35px;
      }

      /deep/.el-input::-webkit-input-placeholder, /deep/.el-input__inner {
        color: #ccc;
      }
    }

    .submit {
      width: 265px;
      line-height: 65px;
      text-align: center;
      font-size: 24px;
      color: #fff;
      background: @skyBlue;
      border-radius: 30px;
      margin: 0 auto;
      margin-top: 35px;
      cursor: pointer;
    }

    .tips {
      width: calc(100% - 50px);
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-top: 1px dashed #ccc;
      font-size: 16px;

      .forget {
        color: #ccc;
        cursor: pointer;
      }

      i {
        color: @skyBlue;
        cursor: pointer;
      }
    }
  }

  .bind_user {
    height: 680px;
  }

  .be_center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 10px;
    background: #fff;
    transform: scale(.9);

    .close {
      position: absolute;
      right: -25px;
      top: -25px;
      cursor: pointer;
    }

    .getCode {
      width: 160px;
      text-align: center;
      line-height: 35px;
      border-radius: 30px;
      background: #666666;
      color: #fff;
      font-size: 18px;
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -35/2px;
      cursor: pointer;
    }
  }
}
</style>