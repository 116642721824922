import request from '../request';

export default {
    getUser(params) {
        return request.get('/user', { params })
    },
    subscribes(params) {
        return request.get('/subscribes', { params })
    },
    update(params) {
        return request.post('/user/update', params)
    },
    history(params) {
        return request.get('/history', { params })
    },
    getShopList(params) {
        return request.get('/shop', { params })
    },
    convert(params) {
        return request.post('/convert', params)
    },
    getConverts(params) {
        return request.get('/converts', { params })
    },
    pointdetailed(params) {
        return request.get('/pointdetailed', { params })
    },
    userSigns(params) {
        return request.get('/user/signs', { params })
    },
    userSigned() {
        return request.post('/user/signed')

    }
}
