<template>
  <div class="header min_width" :class="active ? 'header-live' : ''">
    <div class="content content_center">
      <div class="logo" @click="goPath('/')">
        <img src="@/assets/imgs/logo.png" alt="" />
        <img class="text" src="@/assets/imgs/p1_1.png" alt="" />
      </div>
      <div
        class="nav_item"
        :class="active == 'live' ? 'active' : ''"
        @click="goPath('/live')"
      >
        <img src="@/assets/imgs/p1_7.png" alt="" />
      </div>

      <div
        class="nav_item"
        @click="goPath('/moreTrain')"
        :class="active == 'trainLive' ? 'active' : ''"
      >
        <img src="@/assets/imgs/p1_8.png" alt="" />
      </div>
      <div
        class="nav_item"
        :class="active == 'lookBack' ? 'active' : ''"
        @click="goPath('/moreActivity')"
      >
        <img src="@/assets/imgs/p1_8_1.png" alt="" />
      </div>
      <div
        class="nav_item my"
        @click="goPath('/myCenter')"
        :class="active == 'myCenter' ? 'active' : ''"
      >
        <!-- <img
          @click.stop="goPath('/myCenter?act=0')"
          src="@/assets/imgs/p1_2.png"
          alt=""
        /> -->
        <img
          @click.stop="goPath('/myCenter?act=1')"
          src="@/assets/imgs/p1_3.png"
          alt=""
        />
        <div @click.stop="goPath('/myCenter?act=2')" class="avatar_box">
          <img
            class="avatar"
            :src="avatar ? avatar : require('@/assets/imgs/p1_5.png')"
            alt=""
          />
          <div class="text" v-if="!avatar">
            {{ isYouke ? "游客" : "未登录" }}
          </div>
        </div>
        <!-- <img v-if="!avatar" src="@/assets/imgs/p1_6.png" alt="" /> -->
        <!-- <img v-if="avatar" class="avatar" :src="avatar" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/serves/myCenter";
import { getToken } from "@/utils/auth.js";
export default {
  name: "Header",
  props: ["active"],
  data() {
    return {
      avatar: "",
      isYouke: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    user(val) {
      this.avatar = this.$store.state.user.avatar;
      if (this.$store.state.user == "youke") this.isYouke = true;
    },
  },
  methods: {
    goPath(url) {
      if (url == this.$route.path) return;
      this.$router.push(url);
    },
  },
  mounted() {
    if (this.$store.state.user == "youke") this.isYouke = true;
    var avatar = this.$store.state.user.avatar;
    this.avatar = avatar;
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 99;
  overflow-x: auto;
  &-live {
    background: #005aa4;
  }
  .content {
    height: 135px;
    display: flex;
    align-items: center;
    .logo {
      flex: 1;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 60px;

      .text {
        margin-left: 18px;
      }
    }

    .nav_item {
      flex: 1;
      // width: 250px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s all;
      // margin-left: 65px;
      &:hover {
        background: #2ca6e0;
      }

      &.active {
        background: #2ca6e0;
      }
    }

    .my {
      width: 295px;
      justify-content: space-around;

      .avatar_box {
        position: relative;
        margin-right: 25px;
        .avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .text {
          position: absolute;
          top: -7px;
          left: 38px;
          font-size: 12px;
          background: #2ca6e0;
          color: #fff;
          padding: 5px 4px;
          border-radius: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>