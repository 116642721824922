import axios from 'axios';
import {
    Loading,
    MessageBox,
    Message
} from 'element-ui'
import store from '../store';
import router from '../router'
import {
    ACCESS_TOKEN_KEY,
    getToken,
    removeToken
} from '@/utils/auth'

var loading,
    hide_loading = true;

function isHideLoading() {
    var b = hide_loading;
    hide_loading = false;
    return b;
}

/**
 * 创建axios实例
 */
const request = axios.create({
    baseURL: 'https://newmed.trisanet.com/api', // api的base_url
    timeout: 600000 // 请求超时时间
})
/**
 * 设置拦截器: 请求拦截器
 */
request.interceptors.request.use(config => {
    // if (!isHideLoading()) {
    //     loading = Loading.service();
    // }

    config.headers['content-type'] = 'application/json;charset=UTF-8';
    let url = config.url
    // get参数编码
    if (config.method === 'get' && config.params) {
        url += '?'
        const keys = Object.keys(config.params)
        for (const key of keys) {
            if (config.params[key] != null) {
                url += `${key}=${encodeURIComponent(config.params[key])}&`
            }
        }
        url = url.substring(0, url.length - 1)
        config.params = {}
    }

    if (url.indexOf('?') != -1 && config.method === 'get') {
        url += '&token=' + getToken();
    } else if (url.indexOf('?') == -1 && config.method === 'get') {
        url += '?token=' + getToken();
    }
    if (config.method == 'post') {
        var data = {};
        if (config.data) {
            data = JSON.parse(JSON.stringify(config.data));
        }
        data.token = getToken();
        config.data = JSON.stringify(data)
    }
    config.url = url
    return config
}, error => {
    //loading && loading.close();
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
});

/**
 * 设置拦截器: 响应拦截器
 */
request.interceptors.response.use(
    response => {
        //loading && loading.close();
        const res = response.data;
        if ((res && !res.code)) {
            return res
        }
        if (res.code == 200) {
            // 只有code===0才认为是正常返回
            return res.data
        } else if (res.code == 202) {
            Message({
                type: 'warning',
                message: '登录已过期，请重新登录'
            })
            removeToken();
            router.push('/')
        } else {
            Message({
                type: 'warning',
                message: res.msg
            })
        }

        return Promise.reject(res)
    },

    error => {
        //loading && loading.close();
        console.log(error);
        switch (error.response.status) {
            case 404:
                // 这里处理404错误，比如提示用户或者进行其他操作
                // 你可以在这里做进一步的处理，比如重定向到404页面
                break;
            default:
                // 其他错误处理
                Message({ type: 'error', message: '发生错误，请稍后再试' })
                if (error.response && error.response.data) {
                    try {
                        var data = error.response.data;
                        if (error.response.config.url.indexOf("/api/sys/user/login") >= 0 && (error.response.status === 400 || error.response.status === 403 || error.response.status === 500)) {
                            data = {
                                code: -1,
                                message: "用户名或密码不正确"
                            }
                        } else {
                            if (typeof (data) === 'string') {
                                data = JSON.parse(error.response.data);
                            }
                        }
                        return Promise.reject(data);
                    } catch (e) {
                        return Promise.reject({
                            code: -1,
                            message: "网络异常: " + error.response.data
                        });
                    }
                } else {
                    return Promise.reject({
                        code: -1,
                        message: error
                    })
                }
        }

    }

)

export default request
