import request from '../request';

export default {

    notices(params) {
        return request.get('/notices', { params })
    },
    swipers(params) {
        return request.get('/swipers', { params })
    },
    getVideoArr(params) {
        return request.get('/indexs', { params })
    },
    getVideoLives(params) {
        return request.get('/lives', { params })
    },
    subscribe(params) {
        return request.post('/subscribe', params)
    }
}