import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index/index.vue'
import {
  getToken
} from '@/utils/auth'
import api from "@/api/serves/myCenter";
import store from "@/store/index.js";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/live',
    name: 'live',
    component: () => import('@/views/live/live.vue')
  },
  {
    path: '/trainLive',
    name: 'trainLive',
    component: () => import('@/views/live/peixunLive.vue')
  },
  {
    path: '/lookBack',
    name: 'lookBack',
    component: () => import('@/views/live/lookBack.vue')
  },
  {
    path: '/moreCase',
    name: 'moreCase',
    component: () => import('@/views/more/case.vue')
  },
  {
    path: '/moreTrain',
    name: 'moreTrain',
    component: () => import('@/views/more/train.vue')
  },
  {
    path: '/moreActivity',
    name: 'moreActivity',
    component: () => import('@/views/more/activity.vue')
  },
  {
    path: '/myCenter',
    name: 'myCenter',
    component: () => import('@/views/myCenter/myCenter.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  document.title = '纽脉医疗视频直播系统'
  if(to.path != '/' && !getToken() && !store.state.user) {
    // next('/');
    // console.log(to)
    next();
  } else if (getToken() && !store.state.user) {
    api.getUser()
      .then(res => {
        store.state.user = res;
        next();
      });
  } else {
    next();
  }
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
