<template>
  <div class="subscribeLayer" v-if="subscribeLayerShow">
    <img class="icon" src="@/assets/imgs/yuyue_layer.png" alt="" />
    <div class="success">预约成功</div>
    <div class="tips">直播开始我们会以站内信和短信<br />通知您及时观看</div>
    <div class="btn" @click="onSubscribeLayerShow">关闭</div>
  </div>
</template>

<script>
export default {
  name: "subscribeLayer",
  props: ["subscribeLayerShow"],
  data() {
    return {};
  },
  computed: {},

  methods: {
    onSubscribeLayerShow() {
      this.$emit("onSubscribeLayerShow");
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.subscribeLayer {
  width: 563px;
  height: 525px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  color: #333333;
  z-index: 999;
  border: 1px solid #a1a1a1;
  .icon {
    margin: 60px auto 20px;
  }

  .success {
    font-size: 36px;
  }

  .tips {
    font-size: 24px;
    margin: 30px 0 40px;
    line-height: 35px;
  }

  .btn {
    width: 300px;
    line-height: 70px;
    text-align: center;
    border: 1px solid #333333;
    font-size: 24px;
    border-radius: 50px;
    cursor: pointer;
    margin: auto;
  }
}
</style>