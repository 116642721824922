import request from '../request';

export default {
    login(params) {
        return request.post('/login', params)
    },
    getSms(params) {
        return request.post('/sms', params)
    },
    register(params) {
        return request.post('/register', params)
    },
    resetPasswd(params) {
        return request.post('/resetPasswd', params)
    },
    registerr(params) { // 普通注册
        return request.post('/registerr', params)
    }
}
