<template>
  <div class="index">
    <Header />
    <div class="HeaderSwiper" :style="HeaderSwiper">
    <div class="swiper">
      <!-- <div style="background-image: linear-gradient(270deg, rgb(30, 54, 64) 0%, transparent);"></div> -->
      <div class="swiperRight swiperLeft" ref="backgroundimageLeft"></div>
      <div class="swiperRight" ref="backgroundimageright"></div>
      <swiper
        :options="swiperOptions"
        ref="mySwiper"
        v-if="swiperList.length != 0"
      >
        <!-- slides -->
        <swiper-slide v-for="(item, key) in swiperList" :key="key">
          <img
            :src="item.img"
            alt=""
            :data-href="item.url"
            :data-isinside="item.isinside"
            @load="swiperLoad"
          />
        </swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!--<div class="control_swiper_box">
        <div class="control_swiper">
          <img class="bg" src="@/assets/imgs/p1_23.png" alt="" /> 
          <div class="control_swiper_center">
            <div class="swiper-container control_swiper_content">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div
                    class="s_img"
                    :class="bannerSwiperIndex == key ? 's_img_active' : ''"
                    @mouseenter="bannerSwiperChange(key)"
                    v-for="(item, key) in swiperList"
                    :key="key"
                  >
                    <div class="title1">{{ item.title }}</div>
                    <div class="title2">{{ item.title2 }}</div>
                     <img :src="item.img" alt="" /> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>--> 
    </div>
  </div>
    <div class="content content_center">
      <div class="notice">
        <div class="left_text">直播公告：</div>
        <swiper class="notice_swiper" :options="noticeOptions">
          <!-- slides -->
          <swiper-slide
            class="right_text"
            v-for="(item, key) in noticeList"
            :key="key"
          >
            <div @click="goHref(item.href, item.isinside)">
              {{ item.content }}
            </div>
          </swiper-slide>
        </swiper>
        <!-- <div class="right_text">知识夏日奖励争夺战即将开始 你准备好了吗！</div> -->
      </div>

      <div class="case_activity course_training">
        <div class="title">
          <div class="left">
            <div class="icon">
              <img src="@/assets/imgs/p1_20.png" alt="" />
            </div>
            <div class="name">公司活动</div>
          </div>
          <div class="right" @click="goPath('moreActivity')">
            查看更多<span class="el-icon-d-arrow-right"></span>
          </div>
        </div>
        <div class="course_list">
          <div
            class="course_item"
            v-for="(item, key) in caseSwiperList"
            :key="key"
            v-if="key < 4"
            @click="goLive(item.id, 'lookBack', 'lookBack', item.isinside)"
          >
            <div class="img_box">
              <el-image class="big_img" :src="item.coverimg" >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
              <div class="status">
                <img
                  v-if="item.subscribe == 1"
                  src="@/assets/imgs/p1_18.png"
                  alt=""
                />
                <img
                  v-if="item.subscribe == 0"
                  src="@/assets/imgs/p1_19.png"
                  alt=""
                  @click.stop="yuyueClick(item.id)"
                />
                <img
                  v-if="item.subscribe == 3"
                  src="@/assets/imgs/p1_17.png"
                  alt=""
                />
              </div>
            </div>
            <div class="s_name text_2_hidden">{{ item.title }}</div>
            <div class="time">{{ item.date }}</div>
          </div>
        </div>
      </div>

      <div class="course_training">
        <div class="title">
          <div class="left">
            <div class="icon">
              <img src="@/assets/imgs/p1_16.png" alt="" />
            </div>
            <div class="name">课程培训</div>
          </div>
          <div class="right" @click="goPath('moreTrain')">
            查看更多<span class="el-icon-d-arrow-right"></span>
          </div>
        </div>

        <div class="course_list">
          <div
            class="course_item"
            v-for="(item, key) in courseList"
            :key="key"
            v-if="key < 4"
            @click="goLive(item.id, 'lookBack', 'trainLive', item.isinside)"
          >
            <div class="img_box">
              <el-image class="big_img" :src="item.coverimg" >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
              <div class="status">
                <img
                  v-if="item.subscribe == 1"
                  src="@/assets/imgs/p1_18.png"
                  alt=""
                />
                <img
                  v-if="item.subscribe == 0"
                  src="@/assets/imgs/p1_19.png"
                  alt=""
                  @click.stop="yuyueClick(item.id)"
                />
                <img
                  v-if="item.subscribe == 3"
                  src="@/assets/imgs/p1_17.png"
                  alt=""
                />
              </div>
            </div>
            <div class="s_name text_2_hidden">{{ item.title }}</div>
            <div class="time">{{ item.date }}</div>
          </div>
        </div>
      </div>
    </div>

    <loginLayer :layerShow="layerShow" @closeLayer="closeLayer" />
    <subscribeLayer
      :subscribeLayerShow="subscribeLayerShow"
      @onSubscribeLayerShow="onSubscribeLayerShow"
    />
  </div>
</template>

<script>
import Header from "@/components/header";
import loginLayer from "@/components/login";
import subscribeLayer from "@/components/subscribeLayer";
import ColorThief from "colorthief";
import api from "@/api/serves/index";
import { getToken, setToken } from "@/utils/auth.js";
import Swiper from "swiper";
const colorThief = new ColorThief();
export default {
  name: "index",
  components: {
    Header,
    loginLayer,
    subscribeLayer,
  },
  data() {
    var vm = this;
    return {
      swiperList: [],
      HeaderSwiper:{'width':'100%'},
      loadImg:0,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        grabCursor: true,
        loop: true,
        effect : 'fade',
        speed:  1500,
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 3000, //秒
          disableOnInteraction: false, //滑动不会失效
        },
        autoplayDisableOnInteraction: false,
        observer: true, //解决数据传入后轮播问题
        observeParents: true,
        on: {
          slideChangeTransitionEnd() {
            var index = this.realIndex;
            vm.bannerSwiperIndex = index;
          },
          slideChangeTransitionStart(){
            vm.getStyle();
          },
          click: function (e) {
            let href = e.target.getAttribute("data-href"),
              isinside = e.target.getAttribute("data-isinside");
            vm.isinside = isinside;

            vm.$router.push(href);
          },
        },
        // Some Swiper option/callback...
      },
      bannerSwiperIndex: 0,
      noticeList: [],
      noticeOptions: {
        direction: "vertical",
        autoplay: true,
        grabCursor: true,
      },
      caseSwiperList: [],
      caseSwiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: "auto",
        slidesOffsetBefore: 110,
        slidesOffsetAfter: 75,
        spaceBetween: 82,
        observer: true, //解决数据传入后轮播问题
        observeParents: true,
      },
      courseList: [],
      layerShow: false,
      subscribeLayerShow: false,
    };
  },
  computed: {
    mySwiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    getStyle(){
      if(this.swiperList.length==this.loadImg){
          let url = document.querySelector('.swiper-slide-active img');
          let rgbcolor = colorThief.getColor(url)
          let rgbs = 'rgb('+rgbcolor+')';
          this.$refs.backgroundimageright.style = 'background-image: linear-gradient(270deg, rgb('+rgbcolor+') 0%, transparent)';
          this.$refs.backgroundimageLeft.style = 'background-image: linear-gradient(270deg, rgb('+rgbcolor+') 0%, transparent)';
          this.HeaderSwiper = {'width':'100%','background-color':rgbs}
      }
    },
    swiperLoad(e){
      this.loadImg++
    },
    bannerSwiperChange(key) {
      this.mySwiper.slideTo(key + 1, 200, false);
      this.bannerSwiperIndex = key;
    },
    getNotices() {
      api.notices().then((res) => {
        this.noticeList = res;
      });
    },
    getSwipers() {
      api.swipers().then((res) => {
        this.swiperList = res;
        this.bannerSwiperIndex = 0;
      });
    },
    getVideoArr() {
      api.getVideoArr().then((res) => {
        this.caseSwiperList = res.activitys;
        this.courseList = res.trains;

        if (res.activitys.length <= 3) {
          this.$nextTick(() => {
            this.$refs.caseSwiper.$swiper.navigation.$nextEl[0].classList.add(
              "noClick"
            );
          });
          this.$refs.caseSwiper.$swiper.detachEvents();
        }
      });
    },
    closeLayer() {
      this.layerShow = false;
    },
    goHref(href, isinside) {
      this.isinside = isinside;
      this.$router.push(href);
      // console.log(href);
      // location.href = href;
    },
    goPath(path) {
      this.$router.push(path);
    },
    goLive(id, path, navActive, isinside) {
      this.isinside = isinside;
      this.$router.push({
        path,
        query: { id, navActive },
      });
    },
    onSubscribeLayerShow() {
      this.subscribeLayerShow = false;
    },
    yuyueClick(liveid) {
      api.subscribe({ liveid }).then((res) => {
        this.subscribeLayerShow = true;
        this.getVideoArr();
      });
    },
  },
  mounted() {
    this.getNotices();
    this.getSwipers();
    this.getVideoArr();

    var swiper = new Swiper(".control_swiper_content", {
      direction: "vertical",
      slidesPerView: "auto",
      // slidesOffsetBefore: 70,
      slidesOffsetAfter: 20,
      freeMode: true,
      observer: true,
      observeParents: true,
      mousewheelControl: true,
    });
  },
  beforeRouteLeave(to, from, next) {
    var userInfo = this.$store.state.user;
    if (!userInfo) {
      this.layerShow = true;
    } else {
      var isGOLive =
        (to.path == "/live" && to.query.id) ||
        to.path == "/lookBack" ||
        to.path == "/trainLive"; //如果去的是视频页
      if (userInfo.inside != 1 && this.isinside == 1 && isGOLive) {
        this.$message({
          message: "该视频仅限内部员工观看",
          type: "warning",
        });
        return;
      }
      next();
    }
  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;

.index {
  overflow: hidden;
  background: #f3f3f3;
  .swiperRight{
    position: absolute;
    height: 100%;
    width: 30%;
    right: 0;
    z-index: 2;
  }
  .swiperLeft{
    right:auto;
    left:0;
    transform: rotate(-180deg);
  }
  .swiper {
    width: 100%;
    max-width: 1920px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .swiper-slide img {
      width: 100%;
    }

    /deep/ .swiper-pagination-bullet {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: @sBlue;
      opacity: 1;

      &-active {
        background: @skyBlue;
      }
    }

    .control_swiper_box {
      width: 1250px;
      margin: 0 auto;
      position: relative;

      .control_swiper {
        width: 300px;
        height: 70vh;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 9;
        background: rgba(0, 0, 0, .4);
        overflow: hidden;
        .bg {
          width: 100%;
          height: 100%;
        }

        .control_swiper_content {
          width: 100%;
          // height: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-45%);

          .swiper-slide {
            height: auto;

            .s_img {
              width: 100%;
              // height: 130px;
              // border-radius: 10px;
              overflow: hidden;
              position: relative;
              margin-bottom: 20px;
              cursor: pointer;
              color: #fff;
              font-size: 16px;
              padding: 0 20px;
              line-height: 30px;

              .title1 {
                line-height: 40px;
                font-weight: 700;
              }

              .title2 {
                display: none;
              }

              img {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
              }

              // &::before {
              //   width: 100%;
              //   height: 100%;
              //   content: "";
              //   position: absolute;
              //   top: 0;
              //   left: 0;
              //   background: rgba(0, 0, 0, 0.5);
              // }
            }

            .s_img_active {
              background: rgba(36, 165, 255, 0.30196078431372547);

              .title1 {
                font-size: 24px;
              }

              .title2 {
                display: block;
              }

              &::before {
                background: transparent;
              }

              // &::after {
              //   content: "";
              //   width: 100%;
              //   height: 20px;
              //   background: url(../../assets/imgs/p1_24.png);
              //   position: absolute;
              //   bottom: -12px;
              //   left: 0;
              // }
            }
          }
        }
      }
    }
  }

  .content {
    padding-bottom: 100px;
    .notice {
      width: 1220px;
      margin: 40px auto;
      display: flex;
      align-items: center;
      background: #e7e7e7;
      padding: 12px 15px;
      border-radius: 30px;
      font-size: 16px;
      box-sizing: border-box;
      .left_text {
        color: #666666;
      }

      .notice_swiper {
        flex: 1;
        height: 21px;
      }

      .right_text {
        color: #999999;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 1;

      .left {
        display: flex;
        font-size: 36px;
        .icon {
          display: flex;
          align-items: center;
          border-bottom: 4px solid @skyBlue;
        }

        .name {
          margin-left: 10px;
          padding: 0 10px;
          border-bottom: 4px solid @sBlue;
        }

        div {
          padding-bottom: 10px !important;
        }
      }

      .right {
        font-size: 24px;
        color: #666666;
        cursor: pointer;
      }

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background: #c7c7c7;
        position: absolute;
        bottom: 1px;
        z-index: -1;
      }
    }

    .course_training {
      .course_list {
        margin-top: 58px;
        display: flex;
        flex-wrap: wrap;

        .course_item {
          width: 300px;
          margin-right: 16px;
          margin-bottom: 50px;
          cursor: pointer;
          position: relative;
          padding-bottom: 30px;

          .img_box {
            position: relative;
            border-radius: 10px;
            overflow: hidden;

            .big_img {
              width: 100%;
              height: 169px;
              transition: 0.5s all;
              border-radius: 10px;
              .image-slot {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #c0c4cc;
                background: #f5f7fa;
                height: 169px;
                border-radius: 10px;
              }
              &:hover {
                transform: scale(1.2);
              }
            }
            .status {
              position: absolute;
              bottom: 10px;
              left: 20px;
            }
          }

          .s_name {
            margin-top: 20px;
            font-size: 24px;
          }

          .time {
            position: absolute;
            top: 260px;
            font-size: 18px;
            margin-top: 4px;
            color: #999999;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>